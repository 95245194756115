import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	PaymentContent,
	PaymentContentCenter,
	PaymentItemWrapper,
	PaymentHeader,
	PaymentStyled,
	PaymentTitle,
	OrderDescriptionIconStyled,
	OrderGiftIconStyled,
	QrCodeIconStyled,
	QrCodeWrapper,
	FooterCopyright,
	PaymentBackdrop,
	SbpButtonWrapper,
	OrderRulesWrapper,
	PaymentEndStateWrapper, PaymentStateIconWrapper, PaymentReceipt,
	PaymentStateTitle, PaymentUnavailableCancelWrapper, LoaderWrapper, PaymentSberWrapper
} from './Payment.styled';
import logo from './static/logo.svg';
import { PaymentStore } from './payment.store';
import { OrderAmount } from './components/OrderAmount/OrderAmount.component';
import { OrderItemToggle } from './components/OrderDescription/OrderDescription.component';
import { OrderApplyPromo } from './components/OrderApplyPromo/OrderApplyPromo.component';
import { OrderRules } from './components/OrderRules/OrderRules.component';
import { SbpButton } from './components/SbpButton/SbpButton.component';
import { observer } from 'mobx-react';
import { QrHowToComponent } from './components/QrHowTo/QrHowTo.component';
import { PaymentCancel } from './components/PaymentCancel/PaymentCancel.component';
import { isMobile } from '../utils/is-mobile';
import { SuccessPaidIcon } from '../core/icons/SuccessPaidIcon';
import { ReceiptIcon } from '../core/icons/ReceiptIcon';
import { OrderNumber } from './components/OrderNumber/OrderNumber.component';
import { UnavailablePaymentIcon } from '../core/icons/UnavailablePaymentIcon';
import { Loader } from '../core/icons/Loader';
import { SberPay } from './components/SberPay/SberPay.component';
import { createWidget, Widget } from '@sber-ecom-core/sberpay-widget';
import { useSearchParams } from 'react-router-dom';

const MAX_REQUESTS = 3;
export const Payment: FC<any> = observer(() => {
	const [store] = useState(() => new PaymentStore());
	const params = useParams();
	const [searchParams] = useSearchParams();

	const [isHideToggle, setIsHideToggle] = useState(false);
	const [counter, setCounter] = useState(0);

	const id = (params.id ?? 0) as number;

	async function puller(redirectUrl: string) {
		setCounter(counter + 1);
		const isNext = await store.getState(id, redirectUrl);
		if (isNext && counter < MAX_REQUESTS) {
			setTimeout(() => puller(redirectUrl), 2000);
		}
	}

	useEffect(() => {
		const redirectUrl = searchParams.get('redirect_url') ?? '';

		setIsHideToggle(!isMobile());
		store.getPayment(id, redirectUrl).then(() => {
			puller(redirectUrl);
		});
	}, [store, id]);

	const getReceiptLink = () => {
		if (store.payment?.receipt_link) {
			return;
		}

		const redirectUrl = searchParams.get('redirect_url') ?? '';

		store.getPayment(id, redirectUrl).then(() => {
			if (store.payment?.receipt_link) {
				window.location.href = store.payment?.receipt_link;
			}
		});
	}

	const getSberPayWidget = async () => {
		// const widget = createWidget(process.env.REACT_APP_SBERPAY_ENV as any);
		
		const widget = createWidget('PRODUCTION');

		const redirectUrl = searchParams.get('redirect_url') ?? '';

		const { bankInvoiceId } = await store.getSberPayPayment(id, redirectUrl);

		if (bankInvoiceId) {
			widget?.open({
				bankInvoiceId,
				backUrl: window.location.href
			});
		}
	}

	return <PaymentStyled>
		<PaymentHeader isEndState={!store.payment?.isNew}>
			<img alt='logo' src={logo} width={'188px'}/>
		</PaymentHeader>
		<PaymentContentCenter isEndState={!store.payment?.isNew}>
			<PaymentTitle isEndState={!store.payment?.isNew}>Оплата заказа</PaymentTitle>
			{ !store.isLoaded && <LoaderWrapper>
                <Loader/>
			</LoaderWrapper> }
			{!store.payment && store.isLoaded && <PaymentEndStateWrapper>
                <PaymentStateTitle lowMargin={true}>Заказ недоступен <br/> для оплаты</PaymentStateTitle>
                <PaymentStateIconWrapper>
                	<UnavailablePaymentIcon/>
				</PaymentStateIconWrapper>
                <PaymentUnavailableCancelWrapper>
					<PaymentCancel href={store.redirectUrl} >&lt;&nbsp;&nbsp;Вернуться назад</PaymentCancel>
				</PaymentUnavailableCancelWrapper>
			</PaymentEndStateWrapper>}
			{store.payment?.isPaid &&
                <PaymentEndStateWrapper>
                    <OrderNumber id={store.payment.order_id} shopName={store.payment.russian_trade_name}/>
					<PaymentStateTitle>Оплачен</PaymentStateTitle>
					<PaymentStateIconWrapper>
                    	<SuccessPaidIcon/>
					</PaymentStateIconWrapper>
					<PaymentReceipt onClick={getReceiptLink} href={store.payment.receipt_link}> <ReceiptIcon/> Получить чек</PaymentReceipt>
					{ store.payment?.is_redirect && <PaymentCancel href={store.redirectUrl} >&lt;&nbsp;&nbsp;Вернуться назад</PaymentCancel>}
                </PaymentEndStateWrapper>
			}
			{store.payment?.isNew && <PaymentContent>
                <PaymentBackdrop>
                    <OrderAmount amount={store.payment.amount} discountAmount={store.payment.discount_amount}
                                 shopName={store.payment.russian_trade_name}/>
                    <PaymentItemWrapper>
                        <OrderItemToggle title={'Состав заказа'} icon={<OrderDescriptionIconStyled/>}
                                         content={<div>{store.payment.buyer_description}</div>}/>
                    </PaymentItemWrapper>
                    <PaymentItemWrapper>
                        <OrderItemToggle isHideToggle={isHideToggle} title={'Промокод или сертификат'}
                                         icon={<OrderGiftIconStyled/>}
                                         content={<OrderApplyPromo store={store}/>}/>
                    </PaymentItemWrapper>
                </PaymentBackdrop>
                <PaymentBackdrop>
                    <OrderRulesWrapper>
                        <OrderRules/>
                    </OrderRulesWrapper>
                    <SbpButtonWrapper>
                        <SbpButton link={store.payment.nspk_link}/>
                    </SbpButtonWrapper>
                    <PaymentItemWrapper>
                        <OrderItemToggle title={isHideToggle ? 'QR-код для оплаты по СБП' : 'Скрыть QR-код'}
                                         isHideToggle={isHideToggle} closedTitle={'Показать QR-код'}
                                         icon={<QrCodeIconStyled/>}
										 startIsOpen={!isMobile()}
                                         content={<><QrCodeWrapper><img alt="QR-код для оплаты" width={'100%'}
																		src={store.payment.qr_link ? store.payment.qr_image : `data:image/png;base64, ${store.payment.qr_image}`}/></QrCodeWrapper>
											 <QrHowToComponent/>
										 </>}/>
                    </PaymentItemWrapper>
					<PaymentSberWrapper>
						<SberPay onClick={getSberPayWidget}/>
					</PaymentSberWrapper>
					<PaymentCancel href={store.redirectUrl}>Отменить</PaymentCancel>
                </PaymentBackdrop>

            </PaymentContent>}
			<FooterCopyright>
				<span>© 2023 Московский филиал Туринвойс Пазарьери Электроник Хизметлер А.Ш.</span><br/>
				<span>ИНН 9909659693 КПП 774791001</span><br/>
				<span>Номер записи об аккредитации в РФ 10230002233 16.11.23 МИФНС47</span><br/>
				<span>123112, РОССИЯ, Г. МОСКВА, ПРЕСНЕНСКАЯ НАБ., Д. 12, ПОМ. 10/45</span><br/>
				<span>Круглосуточно: +7 499 325 4898 +90 212 705 36 02</span><br/>
				<span><a href="mailto:yardim@turinvoice.com">yardim@turinvoice.com</a></span><br/>
			</FooterCopyright>
		</PaymentContentCenter>
	</PaymentStyled>;
});
