import React, { FC, useEffect, useState } from 'react';
import { SberPayButton, SberPayIconWrapper } from './SberPay.styled';
import { SberPayIcon } from '../../../core/icons/SberPayIcon';

type SberPayProps = {
    onClick: () => void;
};

export const SberPay: FC<SberPayProps> = ({ onClick }) => {
    return <SberPayButton onClick={onClick}>Оплатить с <SberPayIconWrapper><SberPayIcon/></SberPayIconWrapper> Pay</SberPayButton>
}