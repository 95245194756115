import styled from "styled-components";

export const SberPayButton = styled.button`
    border: 2px solid #21A038;
    text-align: center;
    color: #21A038;
    height: 70px;
    width: 100%;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 600;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SberPayIconWrapper = styled.div`
    margin: 0 6px 0 10px;
`;